<template>
  <v-bottom-navigation
    app
    class="backgroundDark d-flex flex-column"
  >
    <div class="d-flex justify-center">
      <v-btn
        v-for="(item, index) in menu"
        :key="index"
        :to="item.title !== 'Support' ? item.route : null"
        :href="item.title === 'Support' ? item.route : null"
        :target="item.title === 'Support' ? '_blank' : null"
        exact
        class="py-2"
      >
        <span v-if="!$vuetify.breakpoint.xs">{{ $t(item.i18nPath) }}</span>
        <component
          :is="item.icon"
          :color="$vuetify.theme.dark ? '#ffffff' : '#000000'"
          class="menu-icon"
        />
      </v-btn>
    </div>
    <div class="mobile-space" />
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from 'vuex';
import menu from '@/config/menu';
import Call from '@/assets/icons/svg/Call.vue';
import Chart from '@/assets/icons/svg/Chart.vue';
import Logout from '@/assets/icons/svg/Logout.vue';
import Profile from '@/assets/icons/svg/Profile.vue';
import Search from '@/assets/icons/svg/Search.vue';
import Settings from '@/assets/icons/svg/Settings.vue';

export default {
  name: 'BottomMenu',
  components: {
    Call,
    Chart,
    Logout,
    Profile,
    Search,
    Settings,
  },
  computed: {
    ...mapGetters('auth', [
      'roles',
    ]),
    menu() {
      return menu;
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  text-decoration: none !important;
}

.v-bottom-navigation {
  height: auto !important;
}

.menu-icon {
  transform: scale(0.8);
}

.mobile-space {
  height: constant(safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.0 */
  height: env(safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.2 */
}

@media (max-width: 600px) {
  .v-bottom-navigation >>> .v-btn__content {
    padding-left: 0;
    padding-right: 0;
  }
  .v-item-group.v-bottom-navigation .v-btn {
    min-width: 60px;
  }
}

</style>
